/* eslint-disable no-unused-vars */
import { TYPE_CONTAINER, TYPE_ITEM } from '@/constants'
import { ContainerFull } from '@/errors'
import { uuid4 } from '@/utils'

const ns = require('debug')('items')

export enum Size {
	Tiny = 1,
	Small = 10,
	Medium = 20,
	Large = 40,
	Huge = 80,
}

export class GameObject {
	readonly id: string
	readonly key: string
	readonly type: string
	readonly size: bigint
	name: string
	description: string
	isFixture: boolean

	constructor(params: any) {
		this.id = uuid4()
		this.key = params.key
		this.type = params.type
		this.size = params.size
		this.name = params.name || ''
		this.description = params.description || ''
		this.isFixture = params.isFixture || false
	}
}

export class Item extends GameObject {
	constructor(params: any) {
		super({...params, type: TYPE_ITEM})
	}
}

export class Container extends GameObject {
	readonly maxSize: bigint
	readonly maxItems: bigint
	readonly maxItemSize: bigint
	contents: any[]

	constructor(params: any) {
		super({...params, type: TYPE_CONTAINER})
		this.maxSize = params.maxSize
		this.maxItemSize = params.maxItemSize
		this.maxItems = params.maxItems || this.maxSize / this.maxItemSize
		this.contents = []
	}
}

export const OBJ_AREA = 'area'
export const OBJ_BACKPACK = 'backpack'
export const OBJ_BOTTLE = 'bottle'
export const OBJ_HANDS = 'hands'
export const OBJ_MIRROR = 'mirror'
export const OBJ_POCKETS = 'pockets'
export const OBJ_WATER = 'water'
export const OBJ_GRANOLA_BAR = 'granola-bar'
export function createObject(key: string): GameObject {
	if (key === OBJ_AREA) return new Container({ key, name: 'Area', maxSize: Size.Huge * 99, maxItemSize: Size.Huge })
	if (key === OBJ_BACKPACK) return new Container({ key, name: 'Backpack', maxSize: Size.Large * 6, maxItemSize: Size.Large })
	if (key === OBJ_BOTTLE) return new Item({ key, name: 'empty bottle', size: Size.Medium })
	if (key === OBJ_HANDS) return new Container({ key, name: 'Hands', maxSize: Size.Huge, maxItemSize: Size.Huge })
	if (key === OBJ_MIRROR) return new Item({ key, name: 'hand-mirror', size: Size.Small })
	if (key === OBJ_POCKETS) return new Container({ key, name: 'Pockets', maxSize: Size.Medium * 4, maxItemSize: Size.Medium })
	if (key === OBJ_WATER) return new Item({ key, name: 'bottle of water', size: Size.Medium })
	if (key === OBJ_GRANOLA_BAR) return new Item({ key, name: 'granola bar', size: Size.Tiny * 5, description: `Is it food, or...?` })
	return new GameObject({})
}

/**
 * Takes an array of object keys and builds a hierarchical structure of containers and their contents.
 * @param {*} keys the object keys to inflate; containers should be listed before their contents 
 * @returns ids of of all top-level inflated objects (e.g. containers) 
 */
export function inflateInventory(params: any) {
	const log = require('debug')(`${ns.namespace}:${inflateInventory.name}`); log(params)
	const { keys } = params
	const objects: any = { }
	const containerIds: any = []
	let container: Container | null = null
	for (const k in keys) {
		const key = keys[k]
		const obj = createObject(key)
		if (!obj) continue
		log('created object', k, obj)
		objects[obj.id] = obj
		if (obj.type === TYPE_CONTAINER) {
			container = obj as Container
			containerIds.push(obj.id)
		} else {
			if (container) {
				container.contents.push(obj.id)
			} else {
				containerIds.push(obj.id)
			}
		}
	}
	return {objects, containerIds}
}

export function checkContainerCanFitItem(params: any) {
	const log = require('debug')(`${ns.namespace}:${checkContainerCanFitItem.name}`); log(params)
	const { container, item } = params
	if (container.contents.length >= container.maxItems) throw new ContainerFull('too many items', container)
	if (item.size > container.maxItemSize) throw new ContainerFull('item too big', container)
	if (container.contents.reduce((a: any, b: any) => a += b.size, 0) + item.size > container.maxSize) throw new ContainerFull('not enough space', container)
	return true
}
