import { isObject } from '@/utils'
import nlp from 'compromise'
import speech from 'compromise-speech'
import debug from 'debug'
import { DEBUGGING } from './constants'
nlp.plugin(speech)

export const TAG_GO = 'GO'
export const TAG_GET = 'GET'
export const TAG_PUT = 'PUT'
export const VERB_EAT = 'EAT'
export const VERB_USE = 'USE'
export const VERB_DRINK = 'DRINK'
export const TAG_DIRECTION = 'DIRECTION'
export const TERM_EAST = 'EAST'
export const TERM_WEST = 'WEST'
export const TERM_NORTH = 'NORTH'
export const TERM_SOUTH = 'SOUTH'
export const TERM_UP = 'UP'
export const TERM_DOWN = 'DOWN'
export const TERM_OK = 'OK'
export const IN = 'IN'

const ns = debug('nlp')

nlp.plugin({
	// add new tags
	tags: (() => {
		const tags: any = {}
		// notA: 'Liquid',
		tags[TAG_GO] = {isA: 'Verb'}
		tags[TAG_GET] = {isA: 'Verb'}
		tags[TAG_PUT] = {isA: 'Verb'}
		tags[VERB_EAT] = {isA: 'Verb'}
		tags[VERB_DRINK] = {isA: 'Verb'}
		tags[VERB_DRINK] = {isA: 'Verb'}
		tags[TERM_EAST] = {isA: TAG_DIRECTION}
		tags[TERM_WEST] = {isA: TAG_DIRECTION}
		tags[TERM_NORTH] = {isA: TAG_DIRECTION}
		tags[TERM_SOUTH] = {isA: TAG_DIRECTION}
		tags[TERM_UP] = {isA: TAG_DIRECTION}
		tags[TERM_DOWN] = {isA: TAG_DIRECTION}
		return tags
	})(),

	// add new words to the lexicon
	words: (() => {
		const words: any = {}
		words['in'] = IN
		words['into'] = IN
		words['inside'] = IN
		words['k'] = TERM_OK
		words['ok'] = TERM_OK
		words['okay'] = TERM_OK
		words['okey'] = TERM_OK
		words['okies'] = TERM_OK
		words['sure'] = TERM_OK
		words['yes'] = TERM_OK
		words['fine'] = TERM_OK
		words['go'] = TAG_GO
		words['walk'] = TAG_GO
		words['run'] = TAG_GO
		words['travel'] = TAG_GO
		words['leave'] = TAG_GO
		words['up'] = TERM_UP
		words['u'] = TERM_UP
		words['down'] = TERM_DOWN
		words['d'] = TERM_DOWN
		words['east'] = TERM_EAST
		words['e'] = TERM_EAST
		words['west'] = TERM_WEST
		words['w'] = TERM_WEST
		words['north'] = TERM_NORTH
		words['n'] = TERM_NORTH
		words['south'] = TERM_SOUTH
		words['s'] = TERM_SOUTH
		words['use'] = VERB_USE
		words['equip'] = VERB_USE
		words['put'] = TAG_PUT
		words['place'] = TAG_PUT
		words['insert'] = TAG_PUT
		words['store'] = TAG_PUT
		words['stow'] = TAG_PUT
		words['get'] = TAG_GET
		words['take'] = TAG_GET
		words['grab'] = TAG_GET
		words['pickup'] = TAG_GET
		words['pick up'] = TAG_GET
		words['acquire'] = TAG_GET
		words['eat'] = VERB_EAT
		words['consume'] = VERB_EAT
		words['scarf'] = VERB_EAT
		words['gobble'] = VERB_EAT
		words['devour'] = VERB_EAT
		words['drink'] = VERB_DRINK
		words['imbibe'] = VERB_DRINK
		return words
	})(),

	api: (View: any) => {
		//add a new method to compromise
		View.prototype.verbIs = function(verb: string) {
			const m = this.match(`#${verb}`)
			ns('verbIs', m.length > 0, m.text())
			return m.length > 0
		}
	},
})

export function parseText(text: any) {
  const log = debug(`${ns.namespace}:parseText`); log(text)
  const doc = nlp(text)
  if (DEBUGGING) doc.debug()
  return doc
}

export function parseOk(input: any) {
  const log = debug(`${ns.namespace}:${parseOk.name}`); log(input)
	const doc = isObject(input) ? input : nlp(input)
	if (!doc.text() || (doc.match(`#${TERM_OK}`).length && doc.length <= 2)) return true
}

export function parseDirection(input: any) {
	const log = debug(`${ns.namespace}:${parseDirection.name}`); log(input)
	const doc = isObject(input) ? input : nlp(input)

	const result = doc.match(`#${TAG_DIRECTION}`)
	return result.text()
}

export function verbIsGo(input: any) {
	const doc = isObject(input) ? input : nlp(input)
	return doc.verbIs(TAG_GO)
}

export function getTokens(text: string, minLenth = 3, includeText = true) {
	return (includeText ? [text] : []).concat(nlp.tokenize(text).termList().filter((x: any) => x.text.length >= minLenth).map((x: any) => x.text))
}

export function buildNet(matches: any) {
	const log = debug(`${ns.namespace}:${buildNet.name}`); log(matches)
	return nlp.buildNet(matches)
}
