export class GameError extends Error {
  constructor(message: string = '') {
    super(message)
    this.name = this.constructor.name
  }
}

export class ContainerFull extends GameError {
  container: object

  constructor(message: string = '', container: any = null) {
    super(message)
    this.container = container
  }
}

export class ObjectNotFound extends GameError {
  id: any

  constructor(message: string = '', id: any = null) {
    super(message)
    this.id = id
  }
}

export class MultipleMatches extends GameError {
  matches: any

  constructor(message: string = '', matches: any = null) {
    super(message)
    this.matches = matches
  }
}
