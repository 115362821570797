import { OBJ_HANDS, OBJ_POCKETS } from '@/items'

const DEBUG_EXCLUDE = [
	'game:handleKey*',
	'game:showHistoryCommand',
	'store:getObject',
	'store:getAreaId',
]
export const DEBUGGING = !!process.env.VUE_APP_DEBUG
export const DEBUG_NAMESPACES = DEBUGGING ? ['*'].concat(DEBUG_EXCLUDE.map(x => `-${x}`)) : []

export const TYPE_ITEM = 'item'
export const TYPE_CONTAINER_SLOT = 'slot'
export const TYPE_CONTAINER = 'container'
export const COMMAND_HISTORY_SIZE = 10
export const SAVE_KEY = 'parasights-save-data'
export const STARTING_INVENTORY = [OBJ_POCKETS, OBJ_HANDS]
export const DEFAULT_PROMPT = 'What would you like to do?'