import { COMMAND_HISTORY_SIZE, DEFAULT_PROMPT, SAVE_KEY, STARTING_INVENTORY } from '@/constants'
import { GameError, MultipleMatches, ObjectNotFound } from '@/errors'
import { Container, OBJ_AREA, checkContainerCanFitItem, createObject, inflateInventory } from '@/items'
import { TAG_DIRECTION, TAG_GET, TAG_GO, TAG_PUT, TERM_OK, buildNet, getTokens, parseText } from '@/nlp'
import { defaultObject } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'
import { computed, onMounted, ref, shallowRef, watch } from 'vue'
import { useStore } from 'vuex'
import { initialState } from './store'

const ns = require('debug')('game')

export default {
	setup() {
		const store = useStore()
		const debugging = ref(process.env.VUE_APP_DEBUG)
		
		const currentAreaCommands = ref({}) // Populated when area is loaded
		const currentAreaComponent = shallowRef(null)
		const currentAreaId = ref(null)
		const currentAreaKey = computed(() => store.getters.getCurrentAreaKey)
		const commandInputRef = ref(null)
		const commandHistoryOffset = ref(0)
		const commandHistory = computed(() => store.getters.getCommandHistory)
		const gameLoaded = computed(() => store.getters.getGameLoaded)
		const getGameState = computed(() => store.getters.getGameState)
		const prompt = ref(DEFAULT_PROMPT)
		const inventory: any = ref(null)
		
		const areaWrapperClasses = computed(() => {
			return {
				'area-wrapper': true,
			}
		})

		const resetCommandHistoryOffset = () => {
			commandHistoryOffset.value = 0
		}

		watch(
			() => store.state.game.currentAreaKey,
			(newValue, oldValue) => {
				ns(`currentAreaKey changed: ${oldValue} => ${newValue}`)
				loadArea(newValue)
			}
		)

		const loadArea = (key: any) => {
			const log = require('debug')(`${ns.namespace}:${loadArea.name}`); log(key)
			import(`@/components/areas/${key}.vue`).then(component => {
				const area = component.default
				log('loaded', {component, area})
				
				const areaSetup = area.setup
				area.setup = () => {
					// onMounted(() => log('mounted!!!!!!!!!!!!!!!'))
					const setupResult = areaSetup()
					currentAreaCommands.value = setupResult.commands || {}
					return setupResult
				}

				// If this is the first time we've seen the area, set up its initial state
				let areaId = store.getters.getAreaId({key, fallback: null})
				if (!areaId) {
					const areaData = cloneDeep(area.data ? area.data() : {})
					areaId = inflateArea({key, areaData})
				}

				currentAreaId.value = areaId
				currentAreaComponent.value = area
				afterAreaLoaded()
			})
		}

		const inflateArea = (params: any) => {
			const log = require('debug')(`${ns.namespace}:${inflateArea.name}`); log(params)
			const {key, areaData} = params
			const keys = [OBJ_AREA].concat(areaData.objects || [])
			const {objects, containerIds} = inflateInventory({keys})
			const areaId = containerIds[0]
			store.commit('addObjects', {objects})
			store.commit('setAreaId', {key, id: areaId})
			save()
			return areaId
		}

		const load = () => {
			const log = require('debug')(`${ns.namespace}:${load.name}`)
			const saveData = JSON.parse(window.localStorage.getItem(SAVE_KEY) || '{}')
			log('loading save data', saveData)
			const newState = {...cloneDeep(initialState), ...saveData}
			log('replacing state with', {newState})
			store.replaceState(newState)
			loadArea(currentAreaKey.value)
			store.commit('setGameLoadedAt', new Date())
			clearCommand()
			refreshInventory()
		}

		const save = (state: any = null) => {
			const log = require('debug')(`${ns.namespace}:${save.name}`)
			const saveData = cloneDeep(state || store.state)
			log('removing private keys')
			for (const k in saveData) {
				if (k.startsWith('_')) {
					delete saveData[k]
				}
			}
			log('saving', {...saveData})
			window.localStorage.setItem(SAVE_KEY, JSON.stringify(saveData))
		}

		const restart = () => {
			const log = require('debug')(`${ns.namespace}:${restart.name}`)
			currentAreaCommands.value = {}
			currentAreaId.value = null
			currentAreaComponent.value = null
			const commandHistory = cloneDeep(store.getters.getCommandHistory)
			log('preserving command history', {commandHistory})
			const newState = {...cloneDeep(initialState), commandHistory}
			log('creating starting inventory')
			STARTING_INVENTORY.forEach((containerKey) => {
				const object = createObject(containerKey)
				newState.game.objects[object.id] = object
				newState.game.inventory[containerKey] = object.id
			})
			newState.gameStartedAt = new Date()
			save(newState)
			load()
		}

		const afterAreaLoaded = () => {
			focusCommandInput()
		}

		const focusCommandInput = () => {
			if (commandInputRef.value) (commandInputRef.value as any).focus()
		}

		const handleCommandInputBlur = (event: any) => {
			const log = require('debug')(`${ns.namespace}:${handleCommandInputBlur.name}`); log(event)
			// if (DEBUGGING) event.target.value = 'put the bar inside mine pockets'
		}

		const showHistoryCommand = (params: any) => {
			const log = require('debug')(`${ns.namespace}:${showHistoryCommand.name}`); log(params)
			const { offset } = params
			const recentCommands = Object.values(commandHistory.value).reverse().splice(0, COMMAND_HISTORY_SIZE + 1).reverse()
			commandHistoryOffset.value += offset
			if (commandHistoryOffset.value < 0) {
				commandHistoryOffset.value = recentCommands.length - 1
			} else if (commandHistoryOffset.value > recentCommands.length - 1) {
				commandHistoryOffset.value = 0
			}
			const inputElement = commandInputRef.value as any
			if (inputElement) inputElement.value = recentCommands[commandHistoryOffset.value] || ''
		}

		const clearCommand = () => {
			const log = require('debug')(`${ns.namespace}:${clearCommand.name}`); log('begin')
			const inputElement = commandInputRef.value as any
			if (inputElement) inputElement.value = ''
			prompt.value = DEFAULT_PROMPT
			resetCommandHistoryOffset()
		}

		const handleKeyUp = (event: any) => {
			const log = require('debug')(`${ns.namespace}:${handleKeyUp.name}`); log('begin')
			log(event)
			if (event.keyCode === 13 || event.key === 'Enter') {
				// Grab the command and clear the input, then execute
				const text = (event.target.value || '')
				clearCommand()
				processInput({ text })
			}
			else if (event.keyCode === 27 || event.key === 'Escape') {
				clearCommand()
			}
			else if (event.keyCode === 38 || event.key === 'ArrowUp') {
				log('last command')
				showHistoryCommand({ offset: -1 })
			}
			else if (event.keyCode === 40 || event.key === 'ArrowDown') {
				log('next command')
				showHistoryCommand({ offset: +1 })
			}
		}

		/**
		 * Finds all of the game objects in scope and tags any references to them in the NLP doc.
		 * @param {object} params Contains all inputs.
		 */
		const getReferencedObjects = (params: any) => {
			const log = require('debug')(`${ns.namespace}:${getReferencedObjects.name}`); log(params)
			const { doc } = params
			
			refreshInventory()

			const objects: any = {}
			const tokenTags = defaultObject(() => new Set())

			log('adding area objects to scope')
			const area = getContainer({id: currentAreaId.value})
			log('area.contents', area.contents)
			area.contents.forEach((object) => {
				log('object', object)
				objects[object.id] = object
				getTokens(object.key).forEach((token) => tokenTags[token].add(object.id))
				getTokens(object.name).forEach((token) => tokenTags[token].add(object.id))
			})
			
			log('adding inventory to scope')
			for (const c in inventory.value) {
				const container = inventory.value[c]
				log('container', container)
				objects[container.id] = container
				getTokens(container.key).forEach((token) => tokenTags[token].add(container.id))
				getTokens(container.name).forEach((token) => tokenTags[token].add(container.id))
				container.contents.forEach((item: any) => {
					log('item', item)
					objects[item.id] = item
					getTokens(item.key).forEach((token) => tokenTags[token].add(item.id))
					getTokens(item.name).forEach((token) => tokenTags[token].add(item.id))
				})
			}

			log('tokenTags', tokenTags)
			const net = buildNet(Object.keys(tokenTags).map((x) => {
				return {match: x, tag: Array.from(tokenTags[x])}
			}))
			const results = doc.sweep(net).view.settle()
			const terms = results.termList().sort((a: any, b: any) => a.index[1] - b.index[1])
			const objectMatches: any[] = []
			let lastObjectId: any = null
			for (const t in terms) {
				const term = terms[t]
				log('term', term)
				Array.from(term.tags).forEach((tag: any) => {
					const object = objects[tag]
					// Only record an item once if there are consecutive references to it
					if (!!object && object.id !== lastObjectId) {
						objectMatches.push(object)
						lastObjectId = object.id
					}
				})
			}
			log('objectMatches', objectMatches)
			return objectMatches
		}

		const refreshInventory = () => {
			const log = require('debug')(`${ns.namespace}:${refreshInventory.name}`); log('begin')
			const objects: any = {}
			const containerIds = cloneDeep(store.getters.getInventory)
			for (const c in containerIds) {
				const id  = containerIds[c]
				const container = getContainer({id})
				objects[container.id] = container
			}
			inventory.value = Object.values(objects)
		}

		/**
		 * Look for an executable command in the user's input text and try to perform that action.
		 * If successful, save the command to the history.
		 * Either way, save the game.
		 * @param {object} params Contains all inputs.
		 * @param {string} params.text The user's input.
		 */
		const processInput = (params: any) => {
			const log = require('debug')(`${ns.namespace}:${processInput.name}`); log(params)
			const { text } = params
			const command = text || 'ok'
			store.commit('newAction', command)

			const doc = parseText(command)
			log('doc', doc.termList())
			
			const refObjects = getReferencedObjects({doc})

			const handlers = [
				tryActionOk,
				tryActionGo,
				tryActionPut,
				tryActionGet,
			]

			try {
				for (const h in handlers) {
					const handler = handlers[h]
					handler({ doc, refObjects })
					if (store.getters.actionSucceeded) {
						store.commit('saveLastCommand', {command})
						break
					}
				}
			} catch (e: any) {
				log('error', e)
				if (e.message) {
					prompt.value = e.message
				} else if (e.name === ObjectNotFound.name) {
					prompt.value = `Nothing matches that description.`
				} else if (e.name === MultipleMatches.name) {
					prompt.value = `Can you be a little more specific?`
				} else {
					prompt.value = `Oops, that didn't work.`
				}
			} finally {
				save()
			}
		}

		const tryActionOk = (params: any) => {
			const log = require('debug')(`${ns.namespace}:${tryActionGo.name}`); log(params)
			const { doc } = params
			const m = doc.match(`#${TERM_OK}`)
			const t = m.text()
			if (t) {
				log('matched', t, m.termList())
				const command: any = currentAreaCommands.value[TERM_OK]
				if (command) command()
			}
		}

		const tryActionGo = (params: any) => {
			const log = require('debug')(`${ns.namespace}:${tryActionGo.name}`); log(params)
			const { doc } = params
			const m = doc.match(`#${TAG_GO}? #${TAG_DIRECTION}`)
			const t = m.text()
			if (t) {
				log('matched', t, m.termList())
				m.match(`#${TAG_DIRECTION}`).termList()[0].tags.forEach((direction: any) => {
					log(direction)
					const command: any = currentAreaCommands.value[direction]
					if (command) command()
				})
			}
		}

		const tryActionGet = (params: any) => {
			const log = require('debug')(`${ns.namespace}:${tryActionGet.name}`); log(params)
			const { doc, refObjects } = params
			const m = doc.match(`#${TAG_GET} .? #Noun`)
			const t = m.text()
			if (t) {
				log('matched', t, m.termList())
				if (refObjects.length < 1) throw new ObjectNotFound('no items matched', t)
				if (refObjects.length > 1) throw new MultipleMatches('more than one item matched', refObjects)
				const item = refObjects[0]
				if (item.fixture) throw new GameError(`cannot take item ${item.id} of type ${item.type}`)
				for (const c in inventory.value) {
					const container = inventory.value[c]
					moveItem({ item, container })
				}
			}
		}

		const tryActionPut = (params: any) => {
			const log = require('debug')(`${ns.namespace}:${tryActionPut.name}`); log(params)
			const { doc, refObjects } = params
			const m = doc.match(`#${TAG_PUT} .? #Noun #IN (me|my|mine)? #Noun`)
			const t = m.text()
			if (t) {
				log('matched', t, m.termList())
				if (refObjects.length > 2) throw new MultipleMatches()
				if (refObjects.length < 2) throw new ObjectNotFound()
				const item = refObjects[0]
				const container = refObjects[1]
				moveItem({ item, container })
			}
		}

		/**
		 * Since the items are all spawned already, all this will do is remove the item id
		 * from one container's contents and add it to another's. If there's no room in the
		 * target container, throw an error and don't make any changes.
		 * @param {object} params Contains all inputs.
		 * @param {object} params.item The item to move.
		 * @param {object} params.container The container in which to place the item.
		 * @returns success boolean
		 */
		const moveItem = (params: any) => {
			const log = require('debug')(`${ns.namespace}:${moveItem.name}`); log(params)
			const { item, container } = params
			if (item.containerId === container.id) throw new ObjectNotFound('item already in container')
			log('looking for space in', container.key)
			if (checkContainerCanFitItem({container, item})) {
				log('putting', item.key, 'in', container.key)
				// Here we see the classic Diablo item dupe bug:
				// "When moving an item between zones, should we..."
				// "A: put id in target then remove from destination"
				// "B: remove from destination then put id in target"
				// "C: do it in an atomic fashion haha j/k that's hard"
				// Answer: A -- It is far better for the player if we
				// create an extra item than if we delete both, in the
				// very unlikely event that only one operation executes.
				store.commit('putItemInContainer', {itemId: item.id, containerId: container.id})
				store.commit('removeItemFromContainer', {itemId: item.id, containerId: item.containerId})
				store.commit('setActionSuccess', {success: true})
				item.containerId = container.id
				return true
			}
			return false
		}

		/**
		 * Get the container and replace the item list on the container with the actual items.
		 */
		const getContainer = (params: any): Container => {
			const log = require('debug')(`${ns.namespace}:${getContainer.name}`); log(params)
			const { id = null, key = null, inflateContents = true } = params
			let container: any = null
			if (id) {
				container = store.getters.getObject({id, copy: true})
			} else if (key) {
				const containerId = store.getters.getInventoryContainerId({key})
				return getContainer({...params, id: containerId})
			}
			if (inflateContents) {
				container.contents = container.contents.map((x: any) => {
					const object = store.getters.getObject({id: x, copy: true})
					object.containerId = container.id
					return object
			})
			}
			return container
		}

		onMounted(() => {
			const log = require('debug')(`${ns.namespace}:${onMounted.name}`); log('begin')
			load()
		})

		return {
			restart,
			areaWrapperClasses,
			debugging,
			handleCommandInputBlur,
			currentAreaComponent,
			commandInputRef,
			getGameState,
			handleKeyUp,
			focusCommandInput,
			currentAreaId,
			inventory,
			gameLoaded,
			prompt,
		}
	},
}
