<template>
  <ul>
    <li v-if="!isArea" class="container">{{ container.name }}</li>
    <li v-for="id in contents" :key="id">
      <GameObject :id="id"></GameObject>
    </li>
  </ul>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';
import GameObject from '@/components/GameObject.vue';
import { OBJ_AREA } from '@/items';

const props = defineProps(['id'])
const store = useStore()
const container = computed(() => store.getters.getObject({id: props.id}))
const contents = computed(() => container.value.contents)
const isArea = computed(() => container.value.key === OBJ_AREA)
</script>

<style>
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.container {
  font-weight: bold;
  text-decoration: underline;
}
</style>
