import { ObjectNotFound } from '@/errors';
import cloneDeep from 'lodash/cloneDeep';
import { reactive } from 'vue';
import { createStore } from 'vuex';
import { dateAdd, filterObject } from '@/utils';

const ns = require('debug')('store')

export const initialState: any = {
	_actionSuccess: null,
	_currentAreaComponent: null,
	gameStartedAt: null,
	gameLoadedAt: null,
	currentAction: null,
	commandHistory: {}, // Key is time
	game: { // This is the save file
		objects: {}, // Key is id
		condition: 1.00,
		currentAreaKey: 'TheBunker',
		areas: {}, // Key is name, value is object id of item slot
		inventory: {}, // Key is containerKey, value is object ids (each key is a "container slot")
		drankWater: null,
	}
}

const mutations: any = {
	setGameLoadedAt(state: any, t: any) {
		const log = require('debug')(`${ns.namespace}:setGameLoadedAt`); log(t)
		state.gameLoadedAt = t
	},
	goto (state: any, areaKey: any) {
		const log = require('debug')(`${ns.namespace}:goto`); log(areaKey)
		state.game.currentAreaKey = areaKey
		state._actionSuccess = true
	},
	newAction(state: any, payload: any) {
		const log = require('debug')(`${ns.namespace}:newAction`); log(payload)
		const { command = null } = payload
		state.currentAction = command
		this.commit('setActionSuccess', { success: null })
	},
	setActionSuccess(state: any, payload: { success?: true | undefined; }) {
		const log = require('debug')(`${ns.namespace}:setActionSuccess`); log(payload)
		const { success = true } = payload
		state._actionSuccess = success
	},
	saveLastCommand(state: any, payload: { command: any; }) {
		const log = require('debug')(`${ns.namespace}:saveLastCommand`); log(payload)
		const { command } = payload
		if (command) {
			const time = new Date()
			log('Recording last command', command, 'at', time)
			state.commandHistory[time.toISOString()] = command
		}
		this.commit('trimCommandHistory', { before: dateAdd({ days: -2 }) })
	},
	trimCommandHistory(state: any, payload: any) {
		const log = require('debug')(`${ns.namespace}:trimCommandHistory`); log(payload)
		const { before } = payload
		state.commandHistory = filterObject(state.commandHistory, (x: string | number | Date) => new Date(x) >= before)
	},
	addObjects(state: any, payload: any) {
		const log = require('debug')(`${ns.namespace}:addObjects`); log(payload)
		const { objects } = payload
		state.game.objects = { ...state.game.objects, ...objects }
	},
	setAreaId(state: any, payload: any) {
		const log = require('debug')(`${ns.namespace}:setAreaId`); log(payload)
		const { key, id } = payload
		state.game.areas[key] = id
	},
	putItemInContainer(state: any, payload: any) {
		const log = require('debug')(`${ns.namespace}:putItemInContainer`); log(payload)
		const { itemId, containerId } = payload
		const items = [...state.game.objects[containerId].contents, itemId]
		state.game.objects[containerId] = { ...state.game.objects[containerId], contents: items }
	},
	removeItemFromContainer(state: any, payload: any) {
		const log = require('debug')(`${ns.namespace}:removeItemFromContainer`); log(payload)
		const { itemId, containerId } = payload
		state.game.objects[containerId].contents = state.game.objects[containerId].contents.filter((x: any) => x !== itemId)
	},
}

const getters: any = {
	actionSucceeded: (state: any) => state._actionSuccess === true,
	getAreaId: (state: { game: { areas: { [x: string]: any; }; }; }) => (query: { key: any; fallback?: undefined; }) => {
		const log = require('debug')(`${ns.namespace}:getAreaId`); log(query)
		const { key, fallback = undefined } = query
		const areaId = state.game.areas[key]
		const fail = areaId === undefined
		if (fail && fallback === undefined) throw new ObjectNotFound(`${key} not found in state.game.areas`, key)
		return areaId || fallback
	},
	getCommandHistory: (state: any) => state.commandHistory,
	getCurrentAreaComponent: (state: any) => state._currentAreaComponent,
	getCurrentAreaId: (state: any, getters: any) => state.game.areas[getters.getCurrentAreaKey],
	getCurrentAreaKey: (state: any) => state.game.currentAreaKey,
	getGameLoaded: (state: any) => !!state.gameLoadedAt,
	getGameState: (state: any) => state.game,
	getInventory: (state: any) => state.game.inventory,
	getInventoryContainerId: (state: any) => (query: { key: any; fallback?: any; }) => {
		const log = require('debug')(`${ns.namespace}:getInventoryContainerId`); log(query)
		const { key, fallback = undefined } = query
		const containerId = state.game.inventory[key]
		const fail = containerId === undefined
		if (fail && fallback === undefined) throw new ObjectNotFound(`${key} not found in state.game.inventory`, key)
		return containerId || fallback
	},
	getObject: (state: any) => (query: { id: any; copy?: false | undefined; fallback?: any; }) => {
		const log = require('debug')(`${ns.namespace}:getObject`); log(query)
		const { id, copy = false, fallback = undefined} = query
		const object = state.game.objects[id]
		const fail = object === undefined
		if (fail && fallback === undefined) throw new ObjectNotFound(`${id} not found in state.game.objects`, id)
		return object ? (copy ? cloneDeep(object) : object) : fallback
	},
	getState: (state: any) => state,
}

const actions: any = {}

export default createStore({
  state: reactive(cloneDeep(initialState)),
  mutations,
  actions,
  getters,
}) as any
