import { DEBUGGING, DEBUG_NAMESPACES } from '@/constants'
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

if (DEBUGGING) {
  const debug = require('debug')
  debug.enable(DEBUG_NAMESPACES.join(','))
  debug.log = console.log.bind(console)
}

const app = createApp(App)
app.use(store)
app.mount('#app')
