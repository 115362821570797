<template>
	<div id="app">
		<button
			class="new-game"
			@click="restart"
		>New Game</button>
		<div class="wrapper">
			<div v-if="gameLoaded">
				<div :class="areaWrapperClasses" @click="focusCommandInput">
					<div v-if="currentAreaComponent">
						<component :is="currentAreaComponent"></component>
						<InventoryContainer v-if="currentAreaId" class="area-items" :id="currentAreaId"></InventoryContainer>
					</div>
					<div class="inventory">
						<div class="inventory-header"><div v-if="inventory">Inventory:</div><div v-else>You have nothing.</div></div>
						<InventoryContainer v-for="container in inventory" :key="container.id" :id="container.id"></InventoryContainer>
					</div>
				</div>
			</div>
			<input
				type="text"
				ref="commandInputRef"
				class="command"
				@keyup="handleKeyUp"
				@blur="handleCommandInputBlur"
				:placeholder="prompt"
			/>
			<div v-if="debugging" class="debug">
				<div class="header">Game State</div>
				<div class="game-state">{{ getGameState }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import InventoryContainer from '@/components/InventoryContainer.vue'
import GameObject from '@/components/GameObject.vue'
import Script from '@/game'
Script.components = { InventoryContainer, GameObject }
export default Script
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
	font-family: Helvetica, sans-serif;
}
.router-link-active{
	color: red ;
}
.wrapper {
	text-align: center;
}
.area-wrapper {
	font-family: 'Courier New', Courier, monospace;
	height: 500px;
	padding: 10px;
	margin: 0px 50px;
	border: 2px black;
	border-style: dashed;
	background-color: white;
}
.parasiteA {
	font-style: italic;
}
input.command
{
	margin-top: 5px;
	width: 520px;
	font-size: large;
	border: 2px solid black;
}
.debug {
	margin: 10px 40px;
	padding: 5px 20px;
	border: 2px solid grey;
	background-color: wheat;
	text-align: left;
}
.debug .header {
	font-size: 1.5em;
}
.game-state {
	color: grey;
	white-space: pre;
}
.new-game {
	margin: 10px;
	padding: 10px 20px;
}
.area-items {
	margin: 10px;
}
.inventory-header {
	margin: 10px;
}
</style>
