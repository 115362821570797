
import { Options, Vue } from 'vue-class-component';
import TheGame from './components/TheGame.vue';

@Options({
  components: {
    TheGame,
  },
})
export default class App extends Vue {}
