<template>
<div v-if="object">
	{{ object.name || object.key || object.id }}
</div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useStore } from 'vuex'

const props = defineProps(['id'])
const store = useStore()
const object = computed(() => store.getters.getObject({id: props.id}))
</script>

<style>

</style>