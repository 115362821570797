/* eslint-disable no-prototype-builtins */
export const Struct = (...keys: any[]) => ((...v: any[]) => keys.reduce((o, k, i) => {o[k] = v[i]; return o}, {}))

export function sum (arr: any[]) {
  return arr.reduce((acc: any, curr: any) => acc + curr, 0);
}

export function removeItem (item: any, array: any[]) {
  const indexToRemove = array.indexOf(item)
  if (indexToRemove !== -1) {
    array.splice(indexToRemove, 1)
  }
}

export function objectsAreEqual(obj1: { [x: string]: any; }, obj2: { [x: string]: any; }) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

export function arraysAreEqual(arr1: string | any[], arr2: string | any[]) {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}

export function uuid4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function isString(value: any) {
  return typeof value === 'string'
}

export function isObject(value: any): boolean {
  return typeof value === 'object'
}

export function removeDuplicates(arr: any) {
  return arr.filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index)
}

/**
 * Create a new object from an existing object, including only certain keys.
 * @param {object} original The source object to filter.
 * @param {function} predicate A single-argument function that determines whether the a key should be kept.
 * @returns {object}
 */
export function filterObject(original: any, predicate: Function) {
  const subset: any = {}
  for (const key in original) {
    if (predicate(key)) {
      subset[key] = original[key]
    }
  }
  return subset
}

export function dateAdd(params: any) {
  const { date = new Date(), days = 0, seconds = 0 } = params
  const result = new Date(date)
  if (days) result.setDate(result.getDate() + days)
  if (seconds) result.setSeconds(result.getSeconds() + seconds)
  return result
}

export const defaultObject = (defaultFactory: Function) => new Proxy({} as any, {
  get: (target, prop) => { 
    if (target[prop] === undefined) target[prop] = defaultFactory()
    return target[prop]
  }
})
